import { NavLink, RouteComponentProps, withRouter } from "react-router-dom"
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { AuthContext, logout } from "../../core/auth";
import ScrollOut from "scroll-out";
import '../../components/navigation/navbar.css';
// import { getUsersData } from "../../services/firebase-store.service";
import { User } from "../../types/auth";

import logo_text from "../../asset/logo/logo-text2.jpg";




const Header: FC<RouteComponentProps> = ({ history }) => {
    const { user } = useContext(AuthContext);
    const [filterData, setFilterData] = useState<User[]>([]);
    const [data, setData] = useState<User[]>([]);

    useEffect(() => { ScrollOut({ cssProps: true, threshold: 0.5 }); }, []);
    // useEffect(() => { getUsersData().then(res => setData(res)); }, []);

    const onLogout = useCallback(() => void logout().then(() => {
        history.push('/login')
        window.location.replace('/login')
    }), [history]);

    const onSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase();

        if (value == null || value == '') {
            setFilterData([]);
        } else {
            const _data = data.filter((name: any) => {
                if (name && name.firstName) {
                    if ((name.firstName).toLowerCase().includes(value) ||
                        (name.lastName).toLowerCase().includes(value)
                        // ||(name.region).toLowerCase().includes(value)
                    ) { return name }
                }
            })
            setFilterData(_data);
        }
    }

    return (
        <div>
            <div className="container">
                <div className="row align-items-center  px-xl-5">
                    {/* <div className="col-lg-3 d-none d-lg-block">
                        <NavLink to={'/'} className="text-decoration-none">
                            <div className="logo-main">
                                <img src={logo_text} alt="" />
                            </div>
                        </NavLink>
                    </div> */}
                    {/* <div className="col-lg-6 col-6 text-left">
                        <form action="">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search for products" />
                                <div className="input-group-append">
                                    <span className="input-group-text bg-transparent text-primary">
                                        <i className="fa fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div> */}
                    {/* <div className="col-lg-3 col-6 text-right">
                        <a href="" className="btn border">
                            <i className="fas fa-heart text-primary"></i>
                            <span className="badge">0</span>
                        </a>
                        <a href="" className="btn border">
                            <i className="fas fa-shopping-cart text-primary"></i>
                            <span className="badge">0</span>
                        </a>
                    </div> */}
                </div>
            </div>
        </div>


    )
}
export default withRouter(Header);
