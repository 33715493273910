import Carousel from 'better-react-carousel'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// const isImage = ['gif','jpg','jpeg','png'];
export const isVideo = ['mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'mp4'];

const getImagesSlider = (url: string[], showControls: boolean = true) => {
    return url?.map(el => {
        const extension = el.split("?")[0].split(".")[5];
        return isVideo?.includes(extension) ? <video width="90%" src={el} controls={showControls} /> : <img className="w-100 h-100" src={el} alt="Image" />
    },)
}

export const OneSlider = ({ items, cols, gap, autoplay, hideArrow }: any) => <Carousel showDots={false} autoplay={autoplay} hideArrow={hideArrow} cols={cols} rows={1} gap={gap} >
    {
        items.map((el: any, i: number) =>
            <Carousel.Item key={i}>
                <div className='slider-item' >
                    {el}
                </div>
            </Carousel.Item>
        )}
</Carousel>


export const Sliders = ({ items, showDots, hideArrow, slidesToShow, slidesToScroll, custom, autoplay }: any) => {

    const settings = {
        hideArrow: hideArrow,
        dots: showDots,
        infinite: true,
        autoplay: autoplay,
        speed: 1000,
        autoplaySpeed: 5000,
        cssEase: "linear",
        adaptiveHeight: true,
        slidesToShow: slidesToShow || 1,
        slidesToScroll: slidesToScroll || 1,
        customPaging: function (slider, i) {
            return <div className='slider-item'> {getImagesSlider(items, false)[slider]} </div>
        },
        responsive: [

            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
            {custom
                ?
                items.map((el: any, i: number) =>
                    <div key={i} className='slider-item' >
                        {el}
                    </div>
                )
                : getImagesSlider(items)?.map((el: any, i: number) =>
                    <div key={i} className='slider-item' >
                        {el}
                    </div>
                )
            }

        </Slider>
    )

}