import { useContext } from 'react';
import { withNamespaces, } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../core/auth';

const Contact = ({ t }: any) => {
    const { language } = useContext(AuthContext);

    const ad: any = (name: string) => t(name, { returnObjects: true })

    return (
        <div>
            <div className="container-fluid bg-secondary mb-5">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '300px' }}>
                    <h1 className="font-weight-semi-bold text-uppercase mb-3"> {language == 'ua' ? 'Контакти' : 'Contacts'} </h1>
                    <div className="d-inline-flex">
                        <p className="m-0"> <NavLink to={'/'}>{language == 'ua' ? 'Домашня' : 'Home'}</NavLink> </p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0">{language == 'ua' ? 'Контакти' : 'Contacts'}</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row mx-3">
                    <div dangerouslySetInnerHTML={{ __html: ad('Contact') }} />
                </div>
            </div>
        </div>
    )
}
export default withNamespaces()(Contact);