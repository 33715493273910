import { useContext, useEffect } from 'react';
import './cart.css';
import { AuthContext } from '../../core/auth';
import { NavLink } from 'react-router-dom';


export const ShoppingCart = () => {
    const { cart, setCart, language } = useContext(AuthContext);
    const shipping = 0;

    useEffect(() => {
        const _localCart: any = localStorage.getItem("cart")
        const _cart = JSON.parse(_localCart);
        setCart(_cart);
    }, []);

    const changeCount = (isPlus: boolean, i: number) => {
        const _cart: any = Object.assign([], cart);
        if (_cart) {
            if (isPlus) {
                _cart[i].count = cart[i].count + 1;
            }
            else {
                if (_cart[i].count > 1) {
                    _cart[i].count = _cart[i].count - 1;
                }
            }
        }
        setCart(_cart)
    }

    const removeItem = (i: number) => {
        const _cart: any = Object.assign([], cart);
        _cart.splice(i, 1);
        setCart(_cart);
    }

    const getTotal = () => {
        let total = 0;
        cart?.map((el: any) => total += language == 'ua' ? el.product.price.replace(/\,/g, '.') * el.count : el.product.en_price * el.count)
        return total;
    }

    return (
        <div>
            <div className="container-fluid bg-secondary mb-5">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '300px' }}>
                    <h1 className="font-weight-semi-bold text-uppercase mb-3">{language == 'ua' ? 'Корзина' : 'Shopping Cart'}</h1>
                    <div className="d-inline-flex">
                        <p className="m-0">
                            <NavLink to={'/'}>{language == 'ua' ? 'Домашня' : 'Home'}</NavLink>
                        </p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0">{language == 'ua' ? 'Корзина' : 'Shopping Cart'}</p>
                    </div>
                </div>
            </div>

            <div className="container-fluid pt-5 shopping-cart">
                <div className="row px-xl-5">
                    <div className="col-lg-8 table-responsive mb-5">
                        <table className="table table-bordered text-center mb-0">
                            <thead className="bg-secondary text-dark">
                                <tr>
                                    <th>{language == 'ua' ? 'Продукти' : 'Products'} </th>
                                    <th>{language == 'ua' ? 'Ціна' : 'Price'} </th>
                                    <th>{language == 'ua' ? 'Кількість' : 'Quantity'} </th>
                                    <th>{language == 'ua' ? 'Загалом' : 'Total'} </th>
                                    <th>{language == 'ua' ? 'Видалити' : 'Remove'} </th>

                                </tr>
                            </thead>
                            <tbody className="align-middle">
                                {
                                    cart?.map((el: any, i: number) =>
                                        <tr key={i}>
                                            <td className="align-middle img-name"><img src={`${el.product.images[0]}`} alt="" /> <div> {language == 'ua' ? el.product.name : el.product.en_name} </div></td>
                                            <td className="align-middle">{language == 'ua' ? el.product.price : el.product.en_price}</td>
                                            <td className="align-middle">
                                                <div className="input-group quantity mx-auto" style={{ width: '140px' }}>
                                                    <div className="input-group-btn">
                                                        <button onClick={() => changeCount(false, i)} className="btn btn-sm btn-primary btn-minus" >
                                                            <i className="fa fa-minus"></i>
                                                        </button>
                                                    </div>
                                                    <div className="form-control form-control-sm bg-secondary text-center" > {el.count}  </div>
                                                    <div className="input-group-btn">
                                                        <button onClick={() => changeCount(true, i)} className="btn btn-sm btn-primary btn-plus">
                                                            <i className="fa fa-plus"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                {el.color && <div className='color-item'>
                                                    <div className={el.color}>
                                                    </div>
                                                </div>
                                                }
                                            </td>
                                            <td className="align-middle">{language == 'ua' ? `${el.product.price.replace(/\,/g, '.') * el.count} грн ` : `${el.product.en_price * el.count} Euro`} </td>
                                            <td className="align-middle"><button onClick={() => removeItem(i)} className="btn btn-sm btn-primary"><i className="fa fa-times" /></button></td>
                                        </tr>
                                    )
                                }




                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-4">
                        {/* <form className="mb-5" action="">
                        <div className="input-group">
                            <input type="text" className="form-control p-4" placeholder="Coupon Code" />
                            <div className="input-group-append">
                                <button className="btn btn-primary">Apply Coupon</button>
                            </div>
                        </div>
                    </form> */}
                        <div className="card border-secondary mb-5">
                            <div className="card-header card-header-title bg-secondary border-0">
                                <h4 className="font-weight-semi-bold m-0">{language == 'ua' ? 'Резюме корзини' : 'Cart Summary'}</h4>
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-between mb-3 pt-1">
                                    <h6 className="font-weight-medium">{language == 'ua' ? 'Проміжний підсумок' : 'Subtotal'}</h6>
                                    <h6 className="font-weight-medium">{getTotal()} {language == 'ua' ? 'грн' : 'Euro'}</h6>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-medium">{language == 'ua' ? 'Доставка' : 'Shipping'}</h6>
                                    <h6 className="font-weight-medium"> {shipping} {language == 'ua' ? 'грн' : 'Euro'}</h6>
                                </div>
                            </div>
                            <div className="card-footer border-secondary bg-transparent">
                                <div className="d-flex justify-content-between mt-2 .color-w">
                                    <h5 className="font-weight-bold px-2">{language == 'ua' ? 'Загалом' : 'Total'}</h5>
                                    <h5 className="font-weight-bold px-2">{getTotal() + shipping} {language == 'ua' ? 'грн' : 'Euro'}</h5>
                                </div>
                                <NavLink to={'/checkout'}>
                                    <button className="btn btn-block btn-primary my-3 py-3">{language == 'ua' ? 'Перейти до оформлення замовлення' : 'Proceed To Checkout'}</button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}