import swal from 'sweetalert';

export const Modal_Confirm = (confirm: (a: any, b: any) => void, text: string, title: string) => {

    const btnCancel = 'Cancel'
    const btnOK = 'OK'

    swal({
        title: title,
        text: text,
        icon:  "warning" ,
        buttons: [btnCancel, btnOK],
        dangerMode: true
    } as any).then((res) => {
        if (res) {
            if (res) {
                confirm('e','w')
            }
        }
    });
}

