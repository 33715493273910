import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

export const InfiniteScrollComponent = ({ render, data }: any) => {
    const takeOnScroll = 20
    const [renderData, setRenderData] = useState<any>([]);

    useEffect(() => {
        setRenderData(data.slice(0, takeOnScroll))
        return
    }, [data]);

    const getMoreData = () => {
        const _renderData = Object.assign([], renderData)
        _renderData.push(...data.slice(renderData.length, renderData.length + takeOnScroll))
        setRenderData(_renderData)
    }

    return (
        <InfiniteScroll
            hasMore={data.length > renderData.length}
            dataLength={renderData.length}
            loader={<span>Loading ...</span>}
            next={getMoreData}>
            {render && render(renderData)}
        </InfiniteScroll>
    )
}