import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Yup from "yup";
import yupPass from 'yup-password';
// import 'bootstrap/dist/css/bootstrap.css';
import './core/i18n';
yupPass(Yup);

ReactDOM.render(<App />, document.getElementById('root'));
