import { useContext, useEffect, useState } from "react";
import { Product } from "../../../types/Product";
import { addProduct, deleteImg, deleteProduct, updateProduct } from "../../../services/firebase-store.service";
import './addProduct.css'
import { AuthContext } from "../../../core/auth";
import { NotificationSvc } from "../../../components/notification/Notification";
import { Modal_Confirm } from "../../../components/confirm/confirm";
import { withNamespaces, } from 'react-i18next';
import { isVideo } from "../../../components/slider/slider";

const AddProduct = ({ editProduct, onCancel, onUpdate, t }: any) => {
    const { setLoading } = useContext(AuthContext);
    const [product, setProduct] = useState<Product | null>();
    const [imgages, setImgages] = useState<any>([]);
    const { onSuccess, onError } = NotificationSvc();

    useEffect(() => {
        setProduct(editProduct)
        return
    }, []);

    const onChange = (e: any, property: string) => {
        setProduct({ ...product, [property]: e.target.value } as Product);
    }

    const preview = (file: any) => {
        const fr = new FileReader();
        let img: any;
        fr.onload = () => {
            if (/^video/.test(file.type)) {
                img = document.createElement("video");
                img.controls = true;
            } else {
                img = document.createElement("img");
            }
            img.src = fr.result;
            img.alt = file.name;
            if (document) {
                document.querySelector('#preview')?.append(img);
            }
        };
        fr.readAsDataURL(file);
    };

    const uploadImg = async (e: any) => {
        [...e.target.files].forEach(preview)
        setImgages(e.target.files)
    }

    const onAddProduct = async (e: any) => {
        e?.preventDefault()
        if (product?.name && product.code) {
            setLoading(true);
            await addProduct(product as Product, imgages)
            setLoading(false);
            window.location.reload();
        } else {
            onError('Введіть усі дані для продукту!', '')
        }
    }

    const onUpdateProduct = async () => {
        if (product) {
            setLoading(true);
            const _product = await updateProduct(product, imgages)
            onUpdate(_product)
            setLoading(false);
            window.location.reload()
        }
    }

    const cancel = () => { onCancel() }

    const deletePhoto = async (i: number) => {
        if (product) {
            setLoading(true);
            const _product = await deleteImg(product, i)
            setProduct(_product)
            onUpdate(_product, true);
            setLoading(false);
        }
    }

    const deleteItem = async () => {
        setLoading(true);
        await deleteProduct(editProduct)
        onSuccess('Product was deleted successfully')
        setLoading(false);
        window.location.href = "/";
    }

    const onDeleteProduct = async () => {
        if (editProduct) {
            Modal_Confirm(deleteItem, '', 'Are you sure to delete this item?',)
        }
    }

    const ad: any = (name: string) => {
        return t(name, { returnObjects: true })
    }

    const changeType = (event: any, value: any) => {
        const _product = Object.assign({}, product);
        if (!_product[value]) {
            _product[value] = [];
        }
        let updatedList = [..._product[value]];
        if (event.target.checked) {
            updatedList = [..._product[value], event.target.value];
        } else {
            updatedList.splice(_product[value].indexOf(event.target.value), 1);
        }
        _product[value] = updatedList;
        setProduct(_product);
    }

    const setMainPhoto = (i: number) => {
        setProduct({ ...product, mainPhoto: i } as Product);
    }


    return (
        <div className="mt-3 dashbord-page">
            <div className="close-btn" onClick={() => cancel()}>
                <i className="fas fa-window-close"></i>
            </div>
            <h5>{editProduct?.code ? 'Edditing' : 'New'} product</h5>

            <form className='needs-validation' onSubmit={(e) => onAddProduct(e)} >
                <div className="row px-xl-5 pt-5">
                    <div className="col-md-6 mb-5">

                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Назва</span>
                            <input type="text"
                                required
                                className="form-control"
                                placeholder="Назва"
                                aria-label="Назва"
                                aria-describedby="basic-addon1"
                                value={product?.name || ''}
                                onChange={e => onChange(e, 'name')}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Name</span>
                            <input type="text"
                                required
                                className="form-control"
                                placeholder="Name"
                                aria-label="Name"
                                aria-describedby="basic-addon1"
                                value={product?.en_name || ''}
                                onChange={e => onChange(e, 'en_name')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 mb-5">

                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Короткий опис</span>
                            <input type="text"
                                required
                                className="form-control"
                                placeholder="Короткий опис"
                                aria-label="Короткий опис"
                                aria-describedby="basic-addon1"
                                value={product?.shortDescription || ''}
                                onChange={e => onChange(e, 'shortDescription')}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Short description</span>
                            <input type="text"
                                required
                                className="form-control"
                                placeholder="Short description"
                                aria-label="shortDescription"
                                aria-describedby="basic-addon1"
                                value={product?.en_shortDescription || ''}
                                onChange={e => onChange(e, 'en_shortDescription')}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 mb-5">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Опис</span>
                            <textarea required
                                rows={5}
                                placeholder="Опис"
                                className="form-control"
                                aria-label="Опис"
                                value={product?.description || ''}
                                onChange={e => onChange(e, 'description')}
                            ></textarea>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Description</span>
                            <textarea required
                                rows={5}
                                placeholder="Description"
                                className="form-control"
                                aria-label="Description"
                                value={product?.en_description || ''}
                                onChange={e => onChange(e, 'en_description')}
                            ></textarea>
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <span className="input-group-text" id="basic-addon1">{ad('Holiday.name')}</span>
                        <div className="form-check">
                            {
                                ad('Holiday.data').map((el: any, i: number) =>
                                    <div key={i}>
                                        <input className="form-check-input" onChange={(e) => changeType(e, 'holiday')} checked={!!product?.holiday?.find(e => e == i)} type="checkbox" value={i} id={`Holiday${i}`} />
                                        <label className="form-check-label" htmlFor={`Holiday${i}`}>
                                            {el}
                                        </label>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <span className="input-group-text" id="basic-addon1">{ad('Material.name')}</span>
                        <div className="form-check">
                            {
                                ad('Material.data').map((el: any, i: number) =>
                                    <div key={i}>
                                        <input className="form-check-input" onChange={(e) => changeType(e, 'material')} checked={!!product?.material?.find(e => e == i)} type="checkbox" value={i} id={`Material${i}`} />
                                        <label className="form-check-label" htmlFor={`Material${i}`}>
                                            {el}
                                        </label>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <span className="input-group-text" id="basic-addon1">{ad('Interior.name')}</span>
                        <div className="form-check">
                            {
                                ad('Interior.data').map((el: any, i: number) =>
                                    <div key={i}>
                                        <input className="form-check-input" onChange={(e) => changeType(e, 'interior')} checked={!!product?.interior?.find(e => e == i)} type="checkbox" value={i} id={`Interior${i}`} />
                                        <label className="form-check-label" htmlFor={`Interior${i}`}>
                                            {el}
                                        </label>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <span className="input-group-text" id="basic-addon1">{ad('Color.name')}</span>
                        <div className="form-check">
                            {
                                ad('Color.data').map((el: any, i: number) =>
                                    <div key={i}>
                                        <input className="form-check-input" onChange={(e) => changeType(e, 'color')}  checked={!!product?.color?.find(e => e == el.value)} type="checkbox" value={el.value} id={`Color${i}`} />
                                        <label className="form-check-label" htmlFor={`Color${i}`}>
                                            {el.name}
                                        </label>
                                    </div>
                                )
                            }
                            {
                                ad('Color.dataRose').map((el1: any, ii: number = 10) =>
                                    <div key={ii}>
                                        <input className="form-check-input" onChange={(e) => changeType(e, 'color')}  checked={!!product?.color?.find(e => e == el1.value)} type="checkbox" value={el1.value} id={`Color${ii}r`} />
                                        <label className="form-check-label" htmlFor={`Color${ii}r`}>
                                            {el1.name}
                                        </label>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="col-md-6 mb-5">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Код товару</span>
                            <input type="text"
                                required
                                className="form-control"
                                placeholder="Код товару"
                                aria-label="Код товару"
                                aria-describedby="basic-addon1"
                                value={product?.code || ''}
                                onChange={e => onChange(e, 'code')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 mb-5">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Ціна</span>
                            <input type="text"
                                required
                                className="form-control"
                                placeholder="Ціна"
                                aria-label="Ціна"
                                aria-describedby="basic-addon1"
                                value={product?.price || ''}
                                onChange={e => onChange(e, 'price')}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Price Euro</span>
                            <input type="text"
                                required
                                className="form-control"
                                placeholder="Price"
                                aria-label="Price"
                                aria-describedby="basic-addon1"
                                value={product?.en_price || ''}
                                onChange={e => onChange(e, 'en_price')}
                            />
                        </div>
                    </div>
                </div>
                {
                    editProduct?.images &&
                    <div className="imgs">

                        {editProduct.images.map((img: any, i: number) =>
                            <div key={i} className="imgs-item">
                                <div className="imgs-item-btn">
                                    {
                                        !(isVideo?.includes(img.split("?")[0].split(".")[5])) &&
                                        < i onClick={() => setMainPhoto(i)} className={`far ${product?.mainPhoto == i ? 'fa-check-square' : 'fa-square'}`} title="set as main"> main</i>
                                    }
                                    <i onClick={() => deletePhoto(i)} className="fas fa-trash" title="delete"></i>
                                </div>
                                {
                                    isVideo?.includes(img.split("?")[0].split(".")[5])
                                        ? <video src={img} width={80} />
                                        : <img src={img} alt="" />
                                }
                            </div>
                        )}
                    </div>
                }

                <input type="file" multiple id="photo" onChange={(e) => { uploadImg(e) }} />
                <div id="preview"></div>
                {
                    !editProduct?.code &&
                    <div>
                        <button type="submit" className="btn btn-primary btn-block border-0 mt-3 py-3"> ADD </button>
                    </div>
                }
            </form >
            {
                editProduct?.code && <div className="details-btn">
                    <button role="button" onClick={() => onDeleteProduct()} className="btn btn-primary  border-0 mt-3 py-3"> Delete </button>
                    <button role="button" onClick={() => cancel()} className="btn btn-secondary  border-0 mt-3 py-3"> CANCEL </button>
                    <button role="button" onClick={() => onUpdateProduct()} className="btn btn-success  border-0 mt-3 py-3"> UPDATE </button>
                </div>
            }
        </div >
    )

}

export default withNamespaces()(AddProduct);


