import { useContext } from 'react';
import { withNamespaces, } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../core/auth';
import './production.css';

import video1 from '../../asset/video/vr1.mp4';
import video2 from '../../asset/video/vr2.mp4';
import video3 from '../../asset/video/vr3.mp4';
import video4 from '../../asset/video/vr4.mp4';
import video5 from '../../asset/video/002_s.mp4';


// import video3 from '../../asset/video/video01_s.mp4';

import photo1 from '../../asset/photos/prod1.jpg';
import photo2 from '../../asset/photos/prod2.jpg';
import photo3 from '../../asset/photos/prod3.png';
import photo4 from '../../asset/photos/prod4.jpg';
import photo5 from '../../asset/photos/prod5.jpg';
import photo6 from '../../asset/photos/prod6.jpg';


const Production = ({ t }: any) => {
    const { language } = useContext(AuthContext);

    // const ad: any = (name: string) => t(name, { returnObjects: true })

    return (
        <div>
            <div className="container-fluid bg-secondary mb-5">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '300px' }}>
                    <h1 className="font-weight-semi-bold text-uppercase mb-3"> {language == 'ua' ? 'Виробництво' : 'Production'} </h1>
                    <div className="d-inline-flex">
                        <p className="m-0"> <NavLink to={'/'}>{language == 'ua' ? 'Домашня' : 'Home'}</NavLink> </p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0">{language == 'ua' ? 'Виробництво' : 'Production'}</p>
                    </div>
                </div>
            </div>

            {/* <div className="container"> */}
            <div className="row mx-3 video-desc">
                <h3 className='text-center w-100'> <b>ВИРОБНИЦТВО КОВАНИХ КВІТІВ ТА КОМПОЗИЦІЙ</b>
                    <h5>
                        Процес, який поєднує в собі техніки ковальства та мистецтва
                    </h5>
                </h3>
                <div className='col-md-6 pt-5 g-align'>
                    <p className='text-center'>
                        Наша компанія розробила колекцію квітів та композиції зі спеціальним захистом від атмосферних впливів для оздоби пам’ятників, гранітних ваз, стел та інших екстер’єрних конструктивів, які розміщені під відкритим небом. У такому випадку усі вироби проходять декілька етапів захисту, одне з яких цинкування виробів.
                        Деякі вироби мають одразу кріплення для розміщення у горизонтальній площині Кріпити рекомендуємо способом нанесення двохкомпонентних клеїв (на штирі, які вмонтовуються безпосередньо у граніт; на пластини чи інші кріплення, які є частиною виробу).
                    </p>
                </div>

                <div className="col-md-6 v-item">
                    <video width="50%" autoPlay muted loop>
                        <source src={video1} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="col-md-6 v-item">


                    <video width="40%" autoPlay muted loop>
                        <source src={video2} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <video width="40%" autoPlay muted loop>
                        <source src={video3} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <video width="40%" autoPlay muted loop>
                        <source src={video4} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <video width="40%" autoPlay muted loop>
                        <source src={video5} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div className="col-md-6">
                    <h5 className='pt-5'>
                        Основні етапи створення таких виробів:
                    </h5>
                    <div className='sm-desc'>
                        <div>
                            1. **Проектування**: Спочатку необхідно розробити ескіз або план кованої квітки. Це детальний проект з усіма вимірами та деталями.
                        </div>
                        <div>
                            2. **Вибір матеріалів**: Зазвичай для виготовлення кованих квітів використовують метал, наприклад, сталеві або інші листи. Важливо вибрати матеріал, який буде достатньо міцним і в той же час легко оброблюваним.
                        </div>
                        <div>
                            3. **Розігрів металу**: Метал розігрівають до високих температур, щоб зробити його м’яким і легшим для обробки.
                        </div>
                        <div>
                            4. **Кування та формування**: Розігрітий метал обробляють за допомогою ковальських інструментів: молотків, клещів і матриць. На цьому етапі формуються окремі частини квітки, такі як пелюстки, стебла та листя.
                        </div>
                        <div>
                            5. **Зварювання та складання**: Після того, як усі частини квітки виготовлені, їх з’єднують за допомогою зварювання або інших методів кріплення.
                        </div>
                        <div>
                            6. **Остаточна обробка**: Після складання квітка підлягає шліфуванню, поліруванню та додатковому покриттю (фарбування, антикорозійні покриття, лакування, цинкування тощо).
                        </div>
                        <div>
                            7. **Оздоблення**: На цьому етапі можна додавати декоративні елементи, наприклад, кольорові вставки, штучні краплі роси або інші деталі, які надають виробу завершеного вигляду.
                        </div>
                        Ковані квіти можуть використовуватися в різних сферах, таких як декорування інтер’єрів, садових ділянок або як сувеніри чи подарунки.
                    </div>
                </div>

                <div className='text-center w-100'>
                    <h3 className='pt-5'> <b>АНТИКОРОЗІЙНІ ПОКРИТТЯ</b>
                        <h5>
                            Використовуються для захисту металевих виробів від корозії, яка може зменшити їхню міцність і довговічність
                        </h5>
                    </h3>
                </div>

                <div className="col-md-8">
                    <h5 className='pt-5'>
                        Ось кілька типів антикорозійних покриттів і методів їх нанесення:
                    </h5>
                    <div className='sm-desc'>
                        <div>
                            1. **Фарби та лаки**:
                            <br /> - **Фарби на основі епоксидів**: Мають високу стійкість до корозії та агресивних середовищ. Їх часто використовують для покриття металевих конструкцій.
                            <br />  - **Фарби на основі поліуретанів**: Добре тримаються на металі та мають високу стійкість до атмосферних впливів.
                            <br />  - **Фарби на основі хлоркаучуку**: Вони водостійкі і підходять для застосування в умовах високої вологості.
                        </div>
                        <div>
                            2. **Гальванічне покриття**:
                            <br />  - **Цинкове покриття**: Наноситься на метал шляхом електролітичного осадження цинку. Цинк створює захисний шар, який запобігає корозії основного металу.
                            <br /> - **Хромування**: Використовується для покриття металічних поверхонь хромом, що не тільки захищає від корозії, але й надає естетичний блиск.
                        </div>
                        <div>
                            3. **Катодний захист**:
                            <br /> - **Аноди жертвовування**: Цинкові або магнієві аноди прикріплюються до металевих конструкцій, щоб вони кородували замість основного металу.
                            <br />- **Електрохімічний захист**: Застосовується електричний струм для зменшення корозійних процесів на поверхні металу.
                        </div>
                        <div>
                            4. **Пластикові та полімерні покриття**:
                            <br /> - **Полімерні покриття**: Включають порошкову фарбу, яка наноситься на металеву поверхню та потім термічно обробляється для створення міцного захисного шару.
                            <br /> - **Пластикове покриття**: Може включати поліетилен або поліпропілен, які наносяться на метал як рідка або тверда плівка.
                        </div>
                        <div>
                            5. **Мінеральні покриття**:
                            <br /> - **Тефлонові покриття**: Характеризуються високою стійкістю до корозії та хімічних реагентів.
                        </div>

                        <div>
                            Кожний метод має свої переваги і недоліки, і вибір залежить від конкретних умов експлуатації та вимог до захисту.
                        </div>
                    </div>
                </div>

                <div className="col-md-4 v-item">
                    {/* <div className='py-2'>
                            <img src={photo1} width="80%" alt="" />
                        </div> */}
                    <div className='py-2'>
                        <img src={photo4} width="80%" alt="" />
                    </div>
                    <div className='py-2'>
                        <img src={photo5} width="80%" alt="" />
                    </div>
                </div>

                <div className='text-center w-100'>
                    <h3 className='pt-5'> <b>РУЧНА РІЗЬБА СВІЧОК</b>
                        <h5>
                            Техніка, яка дозволяє створювати унікальні і детальні декоративні елементи на поверхні свічок. Ручна різьба на свічках може перетворити прості парафінові чи воскові свічки в справжні витвори мистецтва. Різьба по воску — це мистецтво створення детальних та декоративних візерунків на поверхні воскових свічок. Процес вимагає терпіння, точності та спеціальних інструментів
                        </h5>
                    </h3>
                </div>

                <div className="col-md-4 v-item">
                    <img src={photo2} width="70%" alt="" />
                    <img src={photo1} width="70%" alt="" />
                    {/* <div className='py-2'>
                    </div> */}

                </div>

                <div className="col-md-8">
                    <h5 className='pt-5'>
                        Ось деякі кроки виробництва:
                    </h5>
                    <div className='sm-desc'>
                        <div>
                            1. **Віск**: Можна використовувати свічковий віск, бджолиний віск або парафін. Найкраще використовувати віск без додаткових ароматизаторів або барвників для легшої різьби.
                        </div>
                        <div>
                            2. **Інструменти для різьби**:
                            <br />  - **Ножі для різьби по воску**: Набори ножів з різними лезами спеціально призначені для різьби по воску.
                            <br />  - **Ріжучі інструменти**: Тонкі ріжучі інструменти, шпателі, пінцети.
                            <br />   - **Теплові пістолети або фени**: Для розігрівання та згладжування воску.
                        </div>
                        <div>
                            3. **Форми та шаблони**: Можна використовувати трафарети або шаблони для більш точного дизайну.
                        </div>
                        <div>
                            4. **Розмальовка (опційно)**: Барвники або акрилові фарби для додаткового декорування.
                        </div>
                        <div>
                            5. Свічки додатково покриваються лаком, що підсилює їх декоративність, створює захист та дозволяє користуватися нею впродовж багатьох років.
                        </div>
                    </div>
                </div>



                <div className="col v-item">
                    {/* <div className='py-2'> */}
                    {/* <img src={photo6} width="20%" alt="" /> */}
                    {/* </div>
                    <div className='py-2'> */}
                    {/* <img src={photo1} width="20%" alt="" /> */}
                    {/* </div>
                    <div className='py-2'> */}
                    {/* <img src={photo3} width="20%" alt="" /> */}
                    {/* </div> */}
                </div>


            </div>
            {/* </div> */}
        </div>
    )
}
export default withNamespaces()(Production);