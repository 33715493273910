
import { NotificationSvc } from '../components/notification/Notification';
import { firestore, storage } from "../core/firebase"
import { Address } from '../types/Addredd';
import { Product } from '../types/Product';
import { TypeData, User, getUserValuesFromFirebaseUser } from '../types/auth';

const { onSuccess, onError } = NotificationSvc();

export const getData = async (uid: string, collection: string) => {
    const doc = await firestore.collection(collection).doc(uid).get();
    return doc?.data();
}

export const getProduct = async (id: string) => {
    const pr = await firestore.collection('products').doc(id).get();
    const _data: any = pr.data()
    _data.id = pr.id
    return _data
}

export const getProducts = async () => {
    const ref: any = firestore.collection('products')
    const data: any = [];
    await ref.get()
        .then((querySnapshot: any) => {
            querySnapshot.forEach((doc: any) => {
                const _data = doc.data()
                _data.id = doc.id
                data.push(_data)
            })
        })
        .catch((error: any) => onError("Error getting document: ", error));
    return data;
}

export const addOrder = async (address: Address, products: [{ product: Product | null, count: number }]) => {
    try {
        const a: any = await firestore.collection('orders').add({ address, products })
        return a;
    } catch (error) {
        onError("Error updating document: ", error);
    }
}

export const getOrders = async () => {
    const ref: any = firestore.collection('orders')
    const data: any = [];
    await ref.get()
        .then((querySnapshot: any) => {
            querySnapshot.forEach((doc: any) => {
                const _data = doc.data()
                _data.id = doc.id
                data.push(_data)
            })
        })
        .catch((error: any) => onError("Error getting document: ", error));
    return data;
}

export const updateOrder = async (item: any) => {
    const ref: any = firestore.collection('orders')
    await ref.doc(`${item.id}`).update({ ...item })
}

export const getUserOrders = async (uid: string | number) => {
    const ref: any = firestore.collection('orders')
    const data: any = [];

    await ref.where("address.uid", "==", uid).get()
        .then((querySnapshot: any) => {
            querySnapshot.forEach((doc: any) => {
                data.push(doc.data())
            })
        })
        .catch((error: any) => onError("Error getting document: ", error));
    return data;
}


export const getUserData = async (uid: string | number) => {
    const ref: any = firestore.collection('users')
    let data = null;
    await ref.where("profileId", "==", +uid).get()
        .then((querySnapshot: any) => { querySnapshot.forEach((doc: any) => { data = doc.data() }) })
        .catch((error: any) => onError("Error getting document: ", error));
    return data;
}

export const getStorageDate = async (uid: string, collection: string) => {
    const ref = storage.ref().child(`${uid}/${collection}`)
    return ref.listAll()
        .then((result) => { return result.items.map(async (imageRef) => imageRef.getDownloadURL().then(async (url: string) => url)) })
        .catch((error: any) => onError("Error creating document: ", error));
}

export const deleteFile = async (url: string) => {
    const imageRef = storage.refFromURL(url);
    if (imageRef) {
        await imageRef.delete();
    }
}

export const deleteImg = async (product: Product, i: number) => {
    const _product = Object.assign({}, product)
    await deleteFile(product.images[i]);
    _product.images = product.images.filter(e => e !== product.images[i]);
    await firestore.collection('products').doc(product.id).update({ ..._product })
    return _product
}

export const addProduct = async (product: Product, images: any) => {
    const imgLinks: any = []
    const newItem: any = await createProduct(product)
    product.id = newItem.id;
    for (let index = 0; index < images.length; index++) {
        const a: any = await uploadFile(images[index], product.id)
        imgLinks.push(a)
    }
    product.images = imgLinks
    updateProduct(product, [])
}

export const deleteProduct = async (product: Product) => {
    for (let index = 0; index < product.images.length; index++) {
        if (product.images && product.images[index])
            await deleteFile(product.images[index])
    }
    await firestore.collection('products').doc(product.id).delete()
    return ' Deleted ';
}

export const createProduct = async (product: Product) => {
    try {
        const a: any = await firestore.collection('products').add({ ...product })
        return a
    } catch (error) {
        onError("Error updating document: ", error);
    }
}

export const updateProduct = async (product: Product, images: any) => {
    const imgLinks: any = product.images;
    for (let index = 0; index < images.length; index++) {
        if (typeof images[index] == 'object') {
            const a: any = await uploadFile(images[index], product.id)
            imgLinks.push(a)
        }
    }
    product.images = imgLinks
    try {
        await firestore.collection('products').doc(product.id).update({ ...product })
        return product
    } catch (error) {
        onError("Error updating document: ", error);
    }
}


export const uploadFile = async (file: any, code: any) => {
    const ref = storage.ref();
    const name = +new Date() + "-" + file.name;
    const metadata = { contentType: file.type };
    const task = ref.child('products').child(code).child(`${name}`).put(file, metadata);
    let res: any = null;
    res = await task.then(snapshot => snapshot.ref.getDownloadURL())
        .then(async (url: string) => url)
        .catch((error: any) => onError("Error creating document: ", error));
    return res;
}

export const addCounter = async (uid: string) => {
    let newCount: any = {};
    const ref = firestore.collection('users').doc('counter')
    const _counter = await ref.get().then(querySnapshot => querySnapshot.data())
    if (_counter) {
        newCount = { count: _counter.count + 1 };
        await ref.set(newCount);
    }
    return newCount?.count;
}

export const updateUser = async (uid: string, user: User,) => {
    try {
        const a = await firestore.collection('users').doc(uid).set({ ...user })
    } catch (error) {
        onError("Error updating document: ", error);
    }
}

export const addUser = async (uid: string, user: User,) => {
    try {
        await firestore.collection('users').doc(uid).set({ ...user });
    } catch (error) {
        onError("Error creating document: ", error);
    }
}