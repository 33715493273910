import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD2iJW8TmfCcWmUaXaZ8OsqS3oYwsoEJqo",
  authDomain: "web2-white-rose.firebaseapp.com",
  projectId: "web2-white-rose",
  storageBucket: "web2-white-rose.appspot.com",
  messagingSenderId: "555670150947",
  appId: "1:555670150947:web:a55eb4ac1aa89ccc9ef696",
  measurementId: "G-DRHZ1Q2DHW"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebaseApp.auth();
export const firebaseDb = firebaseApp.database();
export const firebaseFunc = firebaseApp.functions();
export const firestore = firebaseApp.firestore();
export const storage = firebase.storage();
