import { useContext, useEffect, useState } from 'react';
import './profile.css';
import { AuthContext } from '../../core/auth';
import { countryOptions } from '../../services/local-data/countries';
import { User } from '../../types/auth';
import { getUserOrders, updateUser } from '../../services/firebase-store.service';
import { NotificationSvc } from '../../components/notification/Notification';
import { Product } from '../../types/Product';
import { Status } from '../Admin/Orders/orders';

type userTab = {
    name: string,
    nameEng: string,
    isActive: boolean,
}
const TuserTabs: userTab[] = [
    { name: 'Профіль', nameEng: 'Profile', isActive: true },
    { name: 'Замовлення', nameEng: 'Orders', isActive: false },
]

export const Profile = () => {
    const { user, language, changeUser, setLoading } = useContext(AuthContext);
    const [userView, setUserView] = useState<User>({} as User);
    const [userTabs, setUserTabs] = useState<userTab[]>(TuserTabs);
    const [userOrders, setUserOrders] = useState<any>([]);
    const { onSuccess, onError } = NotificationSvc();

    useEffect(() => {
        if (user) {
            setUserView(user);
            getOrders(user.uid);
        }
    }, [user]);

    const getOrders = async (uid: any) => {
        const a: any = await getUserOrders(uid);
        setUserOrders(a)

    }

    const changeUserValue = (value: any, property: string) => {
        const _user = Object.assign({}, userView);
        _user[property] = value;
        setUserView(_user);
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (user) {
            setLoading(true);
            updateUser(user.uid, userView);
            changeUser(userView);
            onSuccess('Profile was updated successfully')
        }
    }

    const changeTab = (inx: number) => {
        const _userTabs = userTabs.map((el: userTab, i: number) => {
            el.isActive = inx == i;
            return el;
        })
        setUserTabs(_userTabs);
    }

    const getTotal = (prod: []) => {
        let total = 0;
        prod.map((el: any) => total += language == 'ua' ? el.product.price.replace(/\,/g, '.') * el.count : el.product.en_price * el.count)
        return total;
    }

    const renderOrders = () => {
        return (
            <div className="row px-xl-5">
                <div className="col-lg-12 table-responsive mb-5">
                    <table className="table table-bordered text-center mb-0">
                        <thead className="bg-secondary text-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{language == 'ua' ? 'Адреса' : 'Address'}</th>
                                <th scope="col">{language == 'ua' ? 'Продукти' : 'Products'}</th>
                                <th scope="col"> {language == 'ua' ? 'Сума (грн)' : 'Total (Euro)'} </th>
                                <th scope="col">{language == 'ua' ? 'Статус' : 'Status'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userOrders?.map((pay: any, i: number) => (
                                    <tr key={i}>
                                        <td scope="row">{i + 1}</td>
                                        <td> {pay.address.country}, {pay.address.state}, {pay.address.city}, {pay.address.address}, {pay.address.zip}</td>
                                        <td>
                                            {pay.products.map((prod: { product: Product, count: number }, ii: number) =>
                                                <td key={ii} className="align-middle img-name p-0">
                                                    <div className="align-middle img-name">
                                                        <img src={`${prod.product.images[0]}`} alt="" />
                                                        <div> {language == 'ua' ? prod.product.name : prod.product.en_name}  </div>
                                                    </div>
                                                    <div className='w-130'>
                                                        {prod.count} X < br />
                                                        {language == 'ua' ? `${prod.product.price} грн ` : `${prod.product.en_price} Euro`}
                                                    </div>
                                                </td>
                                            )}
                                        </td>
                                        <td className="align-middle">{getTotal(pay.products)}</td>
                                        <td className="align-middle">
                                            {
                                                pay.status == Status.pending || !pay.status
                                                    ? <div className="status-td">
                                                        <i className="fas fa-hourglass-end yellow" />
                                                        <div>{language == 'ua' ? `В процесі` : 'Pending'}</div>
                                                    </div>
                                                    : pay.status == Status.cancel
                                                        ? <div className="status-td">
                                                            <i className="fas fa-thumbs-down red" />
                                                            <div>{language == 'ua' ? 'Відхилено' : 'Canceled'} </div>
                                                        </div>
                                                        : <div className="status-td">
                                                            <i className="fas fa-thumbs-up" />
                                                            <div>{language == 'ua' ? 'Відправлено' : 'Shipping'}</div>
                                                        </div>
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="container-fluid bg-secondary mb-5">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '300px' }}>
                    <h1 className="font-weight-semi-bold text-uppercase mb-3">{language == 'ua' ? 'Профіль' : 'Profile'}</h1>
                </div>
            </div>

            <div className="container-fluid pt-2">
                <ul className="nav nav-tabs mb-5">
                    {
                        userTabs.map((tab: userTab, i: number) =>
                            <li key={i} className="nav-item">
                                <div className={`${tab.isActive ? 'active' : ''} nav-link`} onClick={() => changeTab(i)}>
                                    {language == 'ua' ? tab.name : tab.nameEng}
                                </div>
                            </li>
                        )
                    }
                </ul>

                {
                    userTabs[0].isActive &&
                    <div className="row px-xl-5">
                        <div className="col">
                            <div className="mb-4">
                                <div className="row">
                                    <form onSubmit={onSubmit} className="row g-3 needs-validation" >
                                        <div className="col-md-6 form-group">
                                            <label>{language == 'ua' ? `Iм'я, По батькові` : 'First Name'}</label> <br />
                                            <input required className="form-control" type="text" value={userView?.firstName || ''} onChange={(e) => changeUserValue(e.target.value, 'firstName')} />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>{language == 'ua' ? 'Прізвище' : 'Last Name'}</label> <br />
                                            <input required className="form-control" type="text" value={userView?.lastName || ''} onChange={(e) => changeUserValue(e.target.value, 'lastName')} />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>{language == 'ua' ? 'Пошта' : 'E-mail'}</label> <br />
                                            <input required className="form-control" type="email" value={userView?.email || ''} onChange={(e) => changeUserValue(e.target.value, 'email')} />
                                        </div>
                                        <div className="col-md-6 form-group ">
                                            <label>{language == 'ua' ? 'Телефон' : 'Mobile No'}</label>  <br />
                                            <input required className="form-control wight-number" type="text" value={userView?.phoneNumber || ''} onChange={(e) => changeUserValue(e.target.value, 'phoneNumber')} />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>{language == 'ua' ? 'Адреса' : 'Address'}</label>  <br />
                                            <input required className="form-control" type="text" value={userView?.address || ''} onChange={(e) => changeUserValue(e.target.value, 'address')} />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>{language == 'ua' ? 'Країна' : 'Country'}</label>
                                            <br />
                                            <select required className="custom-select" value={userView?.country || ''} onChange={(e) => changeUserValue(e.target.value, 'country')}>
                                                <option value="">... select</option>
                                                {countryOptions.map((el: any, i: number) =>
                                                    <option key={i} value={el.value}>{el.value}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>{language == 'ua' ? 'Місто' : 'City'}</label> < br />
                                            <input required className="form-control" type="text" value={userView?.city || ''} onChange={(e) => changeUserValue(e.target.value, 'city')} />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>{language == 'ua' ? 'Область/район' : 'State'}</label> < br />
                                            <input required className="form-control" type="text" value={userView?.state || ''} onChange={(e) => changeUserValue(e.target.value, 'state')} />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>{language == 'ua' ? 'Поштовий індекс' : 'ZIP Code'}</label> <br />
                                            <input required className="form-control" type="text" value={userView?.zip || ''} onChange={(e) => changeUserValue(e.target.value, 'zip')} />
                                        </div>
                                        <button type='submit' className="btn btn-lg btn-block btn-primary font-weight-bold  m-5 py-3">{language == 'ua' ? 'Зберегти' : 'Save'}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    userTabs[1].isActive &&
                    <div className="container-fluid shopping-cart orders">
                        {renderOrders()}
                    </div>
                }

            </div>
        </div>
    )
}
