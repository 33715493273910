import { createContext } from "react";
import { User, FirebaseUser } from "../types/auth";
import { firebaseAuth } from "./firebase";
import { addUser, getData } from '../services/firebase-store.service';
import { NotificationSvc } from "../components/notification/Notification";
import { Product } from "../types/Product";

interface IAuthContext {
  user: User | null | undefined;
  productSelect: Product;
  products: Product[];
  setProductSelect: (product: Product) => void;
  changeUser: (user: User) => void;
  setLoading: (loading: boolean) => void;
  setLanguage: (lng: string) => void;
  loading: boolean;
  language: string | undefined;
  cart: [{product: Product | null, count: number}];
  setCart: (a : any) => void;
  setProducts: (product: Product[]) => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);
export const logout = () => firebaseAuth.signOut();
const { onError } = NotificationSvc();

export const createUser = async (_user: User, email: string, password: string): Promise<User | void> => {
  try {
    const { user }: any = await firebaseAuth.createUserWithEmailAndPassword(email, password);
    if (user) {
      _user.uid = user.uid
      _user.email = _user.login
      // const AUser: any = { uid: user.uid, ..._user };
      // const newUsr = new User({ ...AUser, password });
      await addUser(_user.uid, _user);
      return _user;
    }
  } catch (error) { onError('', error) }
}

export const loginUser = async (email: string, password: string) => {
  try {
    const { user } = await firebaseAuth.signInWithEmailAndPassword(email, password);
    return await getData((user as FirebaseUser).uid, 'users')
  } catch (error) { onError('', error) }
}




