import { Redirect, Route,  } from "react-router-dom";

export const PrivateRoute = ({comp: Component, user, ...rest}: any) => {
    return (
      <Route
        {...rest}
        render={props => 
          !user ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )} />
    );
  };

