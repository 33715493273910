import { withRouter } from "react-router-dom";
import { Footer } from "./Footer/footer";
import Header from "./Header/header";
import Navbar from "../components/navigation/navbar";

// import a from '../asset/rose.jpg'

 const Layout = (props: any) => (
    <div  className="layout">
      <Header />

      <Navbar />
        <main>
        <div className="container">
         {props.children}
   
         <a href="#" className="btn btn-primary back-to-top" ><i className="fa fa-angle-double-up"></i></a>
          </div>
        </main>
      <Footer />
    </div>
   );

   export default withRouter(Layout);