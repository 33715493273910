import { useContext, useEffect, useState } from 'react';
import './home.css';
import { User } from '../../types/auth';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../core/auth';
import headerVideo1 from '../../asset/video/header-v1.mp4';
import { OneSlider } from '../../components/slider/slider';
import { getProducts } from '../../services/firebase-store.service';
import { Product } from '../../types/Product';
import { withNamespaces } from 'react-i18next';
import { InfiniteScrollComponent } from '../../components/infinity-scroll/infinity_scroll';

const Home = ({ t }: any) => {
  const { setLoading, language, products, setProducts } = useContext(AuthContext);
  // const [_users, setUser] = useState<User[]>([]);
  // const [products, setProducts] = useState<Product[]>([]);
  const [originPoducts, setOriginProducts] = useState<Product[]>([]);
  const [filter, setFilter] = useState<any>({});

  useEffect(() => { window.scrollTo(0, 0) }, [])
  useEffect(() => { getData() }, [])
  useEffect(() => { checkLocalFilter() }, [originPoducts])

  const ad: any = (name: string,) => t(name, { returnObjects: true });

  const getData = async () => {
    const _products = await getProducts();
    setProducts(_products);
    setOriginProducts(_products);
  }

  const checkLocalFilter = () => {
    const a = localStorage.getItem('filter');
    if (a) {
      setFilter(JSON.parse(a));
      filteringData(JSON.parse(a))
    }
  }

  const changeFilter = (e: any, property: string) => {
    const value: any = e?.target.value;
    const _filter = Object.assign({}, filter);
    if (!_filter[property]) { _filter[property] = []; }
    let updatedList = _filter[property];
    const a = _filter[property].findIndex(p => p == value);
    a >= 0 ? updatedList.splice(a, 1) : updatedList = [...updatedList, value];
    _filter[property] = updatedList;

    if (_filter[property].length == 0) {
      delete _filter[property]
    }
    localStorage.setItem("filter", JSON.stringify(_filter));
    setFilter(_filter);
    filteringData(_filter);
  }

  const cancelFilter = () => {
    localStorage.setItem("filter", JSON.stringify({}));
    setFilter({});
    filteringData({});
  }

  const filteringData = (_filter) => {
    setLoading(true);
    let _products: Product[] = []
    let hasFilter = false;
    for (const key in _filter) {
      if (!hasFilter) {
        hasFilter = (_filter[key] && _filter[key].length > 0)
      }
      originPoducts.map((item: Product) => {
        if (item[key]) {
          _filter[key].map(f => {
            const a = item[key]?.find(e => e == f)
            if (a) {
              const b = _products.find(pr => pr.id == item.id)
              if (!b) {
                _products.push(item)
              }
            }
          })
        }
      })
    }
    if (!hasFilter) {
      _products = originPoducts;
      setFilter({});
      localStorage.setItem("filter", JSON.stringify({}));
    }
    setProducts(_products);
    setLoading(false);
  }

  const onSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    let hasFilter = false;

    for (const key in filter) {
      if (!hasFilter) {
        hasFilter = (filter[key] && filter[key].length > 0)
      }
    }
    setLoading(true);
    let _data = originPoducts;
    if (value == null || value == '' || value.length < 3) {
      setLoading(false);
      hasFilter ? filteringData(filter) : setProducts(_data);
    } else {
      _data = originPoducts.filter((propduct: any) => {
        if (language == 'ua') {
          if (propduct && propduct.name) {
            if ((propduct.name).toLowerCase().includes(value)) { return propduct }
          }
        } else {
          if (propduct && propduct.en_name) {
            if ((propduct.en_name).toLowerCase().includes(value)) { return propduct }
          }
        }
      })
      setLoading(false);
      setProducts(_data);
    }
  }

  const renderItems = (_products: Product[]) => {
    return <div className='row'>{_products?.map((product: Product, i: number) =>
      <div key={i} className="catd-item col-lg-4 col-md-6 col-sm-12 pb-3">

        <NavLink to={`/details/${product.id}`}>
          <div className="card product-item border-0 mb-4">
            <div className="card-footer d-flex ">
              <div>
                {product.code}
              </div>
              {/* <i className="fas fa-eye text-primary mr-1" title='Details' /> */}
              <i className="fas fa-shopping-cart text-primary mr-1" title='Add to cart' />
            </div>
            <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
              {
                product?.mainPhoto >= 0
                  ? <img className="img-fluid w-100" src={`${product.images[product?.mainPhoto]}`} alt="" />
                  : <img className="img-fluid w-100" src={`${product.images[0]}`} alt="" />
              }
            </div>
            <div className="card-body border-left border-right text-center p-0 ">
              <div className="title-product">
                {language == 'ua' ? product.name : product.en_name}
              </div>
              <div className="d-flex justify-content-center description">
                {language == 'ua' ? product.shortDescription : product.en_shortDescription}
              </div>
              <div className='product-price' >
                {language == 'ua'  ? <> ціна: <b> {product.price}</b> грн. </> : <> price: <b>  {product.en_price}</b> Euro </>}
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    )
    }
    </div>
  }

  const renderFilter = () => {
    const str: any = []
    for (const property in filter) {
      const upName = property.charAt(0).toUpperCase() + property.slice(1);
      const a = ad(`${upName}.name`);
      str.push(<span key={property}>{a}: </span>)
      filter[property].map((e: any, i: number) => str.push( `${ad(`${upName}.data`)[e]}`, ))
    }
    if (str.length > 0) {
      str.unshift(<span  className='filer_name'> {language == 'ua' ? 'Фільтр ' : 'Filter'} </span>)
      str.push(<i title='Clear' onClick={() => cancelFilter()} className="fas fa-window-close" />)
    }
    return <div className='filer_crumb'>
      {str}


    </div>
  }

  return (
    <>
      {/* __________________________carousel */}
      <div className='mt-2 aaa'>
        <div className='main-video'>

          <OneSlider autoplay={1000} hideArrow gap={1} cols={1} items={[

            <div id="header-carousel" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active" style={{ maxHeight: '670px' }}>
                  <video width="100%" autoPlay muted loop>
                    <source src={headerVideo1} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          ]} />
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="row ">
          {/* __________________________filter */}
          <div className="col-lg-2 col-md-12">

            <div className="border-bottom mb-4 pb-4">
              <h5 className="font-weight-semi-bold mb-4">  {ad('Material.filterName')}</h5>
              {ad('Material.data').map((el: any, i: number) =>
                <div key={i} className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                  <input value={i} type="checkbox" className="custom-control-input" checked={!!filter['material']?.find((x: any) => x == i)} id={`Material${i}`} onChange={(e) => changeFilter(e, 'material')} />
                  <label className="custom-control-label" htmlFor={`Material${i}`}>{el}</label>
                </div>
              )}
            </div>

            <div className="border-bottom mb-4 pb-4">
              <h5 className="font-weight-semi-bold mb-4"> {ad('Interior.filterName')}</h5>
              {ad('Interior.data').map((el: any, i: number) =>
                <div key={i} className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                  <input value={i} type="checkbox" className="custom-control-input" checked={!!filter['interior']?.find((x: any) => x == i)} id={`Interior${i}`} onChange={(e) => changeFilter(e, 'interior')} />
                  <label className="custom-control-label" htmlFor={`Interior${i}`}>{el}</label>
                </div>
              )}
            </div>

            <div className="mb-5">
              <h5 className="font-weight-semi-bold mb-4"> {ad('Holiday.filterName')}</h5>
              {ad('Holiday.data').map((el: any, i: number) =>
                <div key={i} className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                  <input value={i} type="checkbox" className="custom-control-input" checked={!!filter['holiday']?.find((x: any) => x == i)} id={`Holiday${i}`} onChange={(e) => changeFilter(e, 'holiday')} />
                  <label className="custom-control-label" htmlFor={`Holiday${i}`}>{el}</label>
                  {/* <span className="badge border font-weight-normal">1000</span> */}
                </div>
              )}
            </div>
          </div>
          {/* __________________________content */}

          <div className="col-lg-10 col-md-12">
            <div className=''>
              {renderFilter()}
            </div>
            
            <div className="row pb-3">
              <div className="col-12 pb-1">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <form action="">
                    <div className="input-group">
                      <input type="text" onChange={(e) => onSearch(e)} className="form-control" placeholder={language == 'ua' ? 'Пошук по назві' : 'Search by name'} />
                      <div className="input-group-append">
                        <span className="input-group-text bg-transparent text-primary">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                  {/* <div className="dropdown ml-4">
                    <button className="btn border dropdown-toggle" type="button" id="triggerId" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      Sort by
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="triggerId">
                      <a className="dropdown-item" href="#">Latest</a>
                      <a className="dropdown-item" href="#">Popularity</a>
                      <a className="dropdown-item" href="#">Best Rating</a>
                    </div>
                  </div> */}
                </div>
              </div>
              {
                <InfiniteScrollComponent render={renderItems} data={products} />
              }
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default withNamespaces()(Home);