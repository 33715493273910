import { NavLink } from "react-router-dom"

import logo from "../../asset/logo/logo.jpg";
import { useContext } from "react";
import { AuthContext } from "../../core/auth";

export const Footer = () => {
    const {  language } = useContext(AuthContext);

    return (
        <>
            <div className="container-fluid bg-secondary  mt-5 pt-5">
                <div className="row px-xl-5 pt-5">
                    <div className="col-lg-4 col-md-12 mb-5 pr-3 pr-xl-5">
                        <div className="logo-footer mb-4">
                            <img src={logo} alt="" />
                        </div>
                    
                    </div>
                    <div className="col-lg-8 col-md-12">
                        <div className="row">
                            <div className="col-md-8 mb-5">
                                <h5 className="font-weight-bold  mb-4">{language == 'ua' ? 'Швидкі посилання' : 'Quick Links'}</h5>
                                <div className="d-flex flex-column justify-content-start">
                                <NavLink to="/" className="nav-item nav-link"><i className="fa fa-angle-right mr-2"/>{language == 'ua' ? 'Домашня' : 'Home'}</NavLink>
                                <NavLink to="/production" className="nav-item nav-link"><i className="fa fa-angle-right mr-2"/>{language == 'ua' ? 'Виробництво' : 'Production'}</NavLink>
                                <NavLink to="/contact" className="nav-item nav-link"><i className="fa fa-angle-right mr-2"/>{language == 'ua' ? 'Контакти' : 'Contact'}</NavLink>
                                    
                                </div>
                            </div>
                            <div className="col-md-4 mb-5 text-center">
                                <h5 className="font-weight-bold  mb-4">Newsletter</h5>
                                <form action="" className="">
                                    <div className="form-group">
                                        <input type="text" className="form-control border-0 py-4" placeholder="Your Name" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control border-0 py-4" placeholder="Your Email"
                                            required />
                                    </div>
                                    <div>
                                        <button className="btn btn-primary btn-block border-0 py-3" type="submit">Subscribe Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row border-top border-light mx-xl-5 py-4">
                    <div className="col-md-6 px-xl-0">
                        <p className="mb-md-0 text-center text-md-left ">
                            &copy; <a className=" font-weight-semi-bold" href="#">Your Site Name</a>. All Rights Reserved. Designed
                            by
                            <a className=" font-weight-semi-bold" href="https://htmlcodex.com">HTML Codex</a><br />
                            Distributed By <a href="https://themewagon.com" target="_blank">ThemeWagon</a>
                        </p>
                    </div>
                    <div className="col-md-6 px-xl-0 text-center text-md-right">
                        <img className="img-fluid" src="img/payments.png" alt="" />
                    </div>
                </div> */}
            </div>


        </>
    )
}