import { NavLink } from 'react-router-dom';
import './checkout.css';
import { useContext, useEffect, useState } from 'react';
import { AuthContext, createUser, loginUser } from '../../core/auth';
import { countryOptions } from '../../services/local-data/countries';
import { Address } from '../../types/Addredd';
import { addOrder } from '../../services/firebase-store.service';
import { NotificationSvc } from '../notification/Notification';
import { useHistory } from 'react-router-dom';

export const Checkout = () => {
    const { onSuccess, onError } = NotificationSvc();
    const { cart, setCart, language, changeUser, user } = useContext(AuthContext);
    const [shippingAddress, setShippingAddress] = useState<Address>({} as Address);
    const [createAccount, setCreateAccount] = useState<boolean>(false);
    const shipping = 0;
    const history = useHistory();

    useEffect(() => {
        const _localCart: any = localStorage.getItem("cart")
        const _cart = JSON.parse(_localCart);
        setCart(_cart);
    }, []);

    useEffect(() => {
        if (user) {
            const _address = {
                address: user.address,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                country: user.country,
                city: user.city,
                state: user.state,
                zip: user.zip,
                phoneNumber: user.phoneNumber,
            } as Address
            setShippingAddress(_address);
        }
    }, [user]);

    const getTotal = () => {
        let total = 0;
        cart?.map((el: any) => total += language == 'ua' ? el.product.price.replace(/\,/g, '.') * el.count : el.product.en_price * el.count)
        return total;
    }

    const changeShippingValue = (value: any, property: string) => {
        const _shippingAddress = Object.assign({}, shippingAddress);
        _shippingAddress[property] = value;
        setShippingAddress(_shippingAddress);
    }

    const createOrder = (e) => {
        const a: any = document.getElementById('submit');
        a?.click();
    }

    const addNewOrder = async (userId: string = '') => {
        shippingAddress.uid = userId;
        const a = await addOrder(shippingAddress, cart);
        if (a) {
            localStorage.clear();
            setCart([]);
            onSuccess('Order was created successfully');
            history.push('/');
        }
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();
        if (createAccount) {
            shippingAddress.login = shippingAddress.email
            createUser(shippingAddress as any, shippingAddress.email, `${shippingAddress.password}`)
                .then(async (res: any) => {
                    await addNewOrder(res?.uid);
                    loginUser(res.email, res.password).then((u: any) => {
                        changeUser(u);
                    })
                }
                ).catch(e => onError('', e));
        } else {
            addNewOrder(user?.uid);
        }
    }


    return (
        <div>
            <div className="container-fluid bg-secondary mb-5">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '300px' }} >
                    <h1 className="font-weight-semi-bold text-uppercase mb-3">{language == 'ua' ? 'Замовлення' : 'Checkout'}</h1>
                    <div className="d-inline-flex">
                        <p className="m-0">
                            <NavLink to={'/'}> {language == 'ua' ? 'Домашня' : 'Home'}</NavLink>
                        </p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0">
                            <NavLink to={'/cart'}>{language == 'ua' ? 'Корзина' : 'Shopping Cart'}</NavLink>
                        </p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0"> {language == 'ua' ? 'Замовлення' : 'Checkout'} </p>
                    </div>
                </div>
            </div>

            <div className="container-fluid pt-5">
                <div className="row px-xl-5">
                    <div className="col-lg-8">
                        <div className="mb-4">
                            <h4 className="font-weight-semi-bold mb-4">{language == 'ua' ? 'Адреса доставки' : 'Shipping Address'}</h4>
                            <div className="row">

                                <form onSubmit={onSubmit} className="row g-3 needs-validation" >
                                    <div className="col-md-6 form-group">
                                        <label>{language == 'ua' ? `Iм'я, По батькові` : 'First Name'}</label> <br />
                                        <input required className="form-control" type="text" value={shippingAddress?.firstName || ''} onChange={(e) => changeShippingValue(e.target.value, 'firstName')} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>{language == 'ua' ? 'Прізвище' : 'Last Name'}</label>
                                        <input required className="form-control" type="text" value={shippingAddress?.lastName || ''} onChange={(e) => changeShippingValue(e.target.value, 'lastName')} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>{language == 'ua' ? 'Пошта' : 'E-mail'}</label> <br />
                                        <input required className="form-control" type="email" value={shippingAddress?.email || ''} onChange={(e) => changeShippingValue(e.target.value, 'email')} />
                                    </div>
                                    <div className="col-md-6 form-group ">
                                        <label>{language == 'ua' ? 'Телефон' : 'Mobile No'}</label>
                                        <input required className="form-control wight-number" type="text" value={shippingAddress?.phoneNumber || ''} onChange={(e) => changeShippingValue(e.target.value, 'phoneNumber')} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>{language == 'ua' ? 'Адреса' : 'Address'}</label>
                                        <input required className="form-control" type="text" value={shippingAddress?.address || ''} onChange={(e) => changeShippingValue(e.target.value, 'address')} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>{language == 'ua' ? 'Країна' : 'Country'}</label>
                                        <br />
                                        <select required className="custom-select" value={shippingAddress?.country || ''} onChange={(e) => changeShippingValue(e.target.value, 'country')}>
                                            <option value="">... select</option>
                                            {countryOptions.map((el: any, i: number) =>
                                                <option key={i} value={el.value}>{el.value}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>{language == 'ua' ? 'Місто' : 'City'}</label> < br />
                                        <input required className="form-control" type="text" value={shippingAddress?.city || ''} onChange={(e) => changeShippingValue(e.target.value, 'city')} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>{language == 'ua' ? 'Область/район' : 'State'}</label> < br />
                                        <input required className="form-control" type="text" value={shippingAddress?.state || ''} onChange={(e) => changeShippingValue(e.target.value, 'state')} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>{language == 'ua' ? 'Поштовий індекс' : 'ZIP Code'}</label>
                                        <input required className="form-control" type="text" value={shippingAddress?.zip || ''} onChange={(e) => changeShippingValue(e.target.value, 'zip')} />
                                    </div>
                                    {
                                        !user &&
                                        <div className="col-md-12 form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" checked={createAccount} onClick={() => setCreateAccount(!createAccount)} className="custom-control-input" id="newaccount" />
                                                <label className="custom-control-label" htmlFor="newaccount">{language == 'ua' ? 'Створити аккаунт' : 'Create an account'}</label>
                                            </div>
                                        </div>
                                    }

                                    {createAccount &&
                                        <div className="col-md-6 form-group">
                                            <label>{language == 'ua' ? 'Пароль' : 'Password'}</label>
                                            <input required className="form-control" type="text" minLength={6} value={shippingAddress?.password || ''} onChange={(e) => changeShippingValue(e.target.value, 'password')} />
                                        </div>
                                    }
                                    <button type='submit' id="submit" className='hidden'></button>
                                </form>
                            </div>
                        </div>
                        <div className="collapse mb-4" id="shipping-address">

                            <div className="row">

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card border-secondary mb-5">
                            <div className=" card-header card-header-title bg-secondary border-0">
                                <h4 className="font-weight-semi-bold m-0">{language == 'ua' ? 'Сума замовлення' : 'Order Total'}</h4>
                            </div>
                            <div className="card-body">
                                <h5 className="font-weight-medium mb-3">{language == 'ua' ? 'Продукти' : 'Products'}</h5>
                                {cart.map((el: any, i: number) =>
                                    <div key={i} className="d-flex justify-content-between">
                                        <p>{language == 'ua' ? el.product.name : el.product.en_name}</p>
                                        <p className='price-cart'>{el.count} x {language == 'ua' ? `${el.product.price} грн ` : `${el.product.en_price} Euro`} </p>
                                    </div>
                                )}

                                <hr className="mt-0" />
                                <div className="d-flex justify-content-between mb-3 pt-1">
                                    <h6 className="font-weight-medium">{language == 'ua' ? 'Проміжний підсумок' : 'Subtotal'}</h6>
                                    <h6 className="font-weight-medium">{getTotal()} {language == 'ua' ? 'грн' : 'Euro'}</h6>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-medium">{language == 'ua' ? 'Доставка' : 'Shipping'}</h6>
                                    <h6 className="font-weight-medium">{shipping} {language == 'ua' ? 'грн' : 'Euro'}</h6>
                                </div>
                            </div>
                            <div className="card-footer border-secondary bg-transparent">
                                <div className="d-flex justify-content-between mt-2 color-w">
                                    <h5 className="font-weight-bold px-4">{language == 'ua' ? 'Загалом' : 'Total'}</h5>
                                    <h5 className="font-weight-bold px-4">{getTotal() + shipping} {language == 'ua' ? 'грн' : 'Euro'}</h5>
                                </div>
                                <button onClick={(e) => createOrder(e)} className="btn btn-lg btn-block btn-primary font-weight-bold my-3 py-3">{language == 'ua' ? 'Зробити замовлення' : 'Place Order'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}