import { useContext, useEffect, useState } from 'react';
import './product-details.css';
import '../../pages/Home/home.css';
import { withRouter } from 'react-router-dom';
import { Sliders } from '../slider/slider';
import { AuthContext } from '../../core/auth';
import { Product } from '../../types/Product';
import { NotificationSvc } from '../notification/Notification';
import { getProduct } from '../../services/firebase-store.service';
import Dashboard from '../../pages/Admin/AddProduct/addProduct';
import { withNamespaces } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const moveItem = (array, to, from) => {
    const item = array[from];
    array.splice(from, 1);
    array.splice(to, 0, item);
    return array;
};

const ProductDetails = ({ match, t }: any) => {
    const { setLoading, language, user, cart, setCart, products } = useContext(AuthContext);
    const [editProduct, setEditProduct] = useState<any>();
    const [productSelect, setProductSelect] = useState<any>();
    const [count, setCount] = useState<number>(1);
    const [color, setColor] = useState<string>('');
    const { onSuccess, onError } = NotificationSvc();

    useEffect(() => { scrollUp() }, []);

    const scrollUp = () => {
        window.scrollTo(0, 0)
    }

    const ad: any = (name: string,) => {
        const list = t(name, { returnObjects: true },)
        return list
    }

    useEffect(() => {
        const params: any = match.params;
        if (params && params.id) {
            getData(params.id);
        }
    }, []);

    const getData = async (id: string) => {
        setLoading(true);
        const _data = await getProduct(id);
        if (_data) {
            if (_data.mainPhoto >= 0) {
                _data.images = moveItem(_data.images, _data.mainPhoto, 0);
            }
            setProductSelect(_data);
            if (_data.color?.length > 0) {
                setColor(_data?.color[0]);
            }
        }
        setLoading(false);
    }

    const onUpdate = (product: Product, isDelImg: boolean) => {
        setProductSelect(product)
        setEditProduct(null)
        onSuccess('Product was updated successfully')
    }

    const changeCount = (isPlus: boolean) => {
        if (isPlus) {
            setCount(count + 1);
        } else {
            if (count > 1) {
                setCount(count - 1);
            }
        }
    }

    const addToCart = () => {
        const _cart: any = Object.assign([], cart);
        const inx = _cart.findIndex((item: any) => (item.product.id == productSelect.id) && (!color || item?.color == color));
        if (inx >= 0) {
            _cart[inx].count = _cart[inx].count + count;
        } else {
            _cart.push({ product: productSelect, count, color });
        }
        setCart(_cart);
        onSuccess('Product was added to cart successfully');
    }

    const renderItems = (_products: Product[]) => {
        return _products?.map((product: Product, i: number) =>
            <div key={i} className="catd-item ">

                <div className="card product-item border-0 mb-4">
                    <div className="card-footer d-flex ">
                        <button onClick={() => { setProductSelect(product); scrollUp() }} className="btn btn-primary btn-details" >
                            {language == 'ua' ? 'Переглянути' : 'View'} <i className="fas fa-eye"></i>
                        </button>
                    </div>
                    <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                        {
                            product?.mainPhoto >= 0
                                ? <img className="img-fluid w-100" src={`${product.images[product?.mainPhoto]}`} alt="" />
                                : <img className="img-fluid w-100" src={`${product.images[0]}`} alt="" />
                        }
                    </div>
                    <div className="card-body border-left border-right text-center p-0 ">
                        <div className="title-product">
                            {language == 'ua' ? product.name : product.en_name}
                        </div>
                        <div className="d-flex justify-content-center description">
                            {language == 'ua' ? product.shortDescription : product.en_shortDescription}
                        </div>
                        <div className='product-price' >
                            {language == 'ua' ? <> ціна: <b> {product.price}</b> грн. </> : <> price: <b>  {product.en_price}</b> Euro </>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
            {
                editProduct &&
                <div className='modal-edit'>
                    <div className='modal-edit-item'>
                        <Dashboard editProduct={editProduct} onCancel={() => setEditProduct(null)} onUpdate={(p: Product, isDelImg) => onUpdate(p, isDelImg)} />
                    </div>
                </div>
            }
            {productSelect &&
                <div className="container-fluid py-5 ">
                    <div className="row px-xl-5 ">
                        <div className="col-lg-6 slider-group">
                            <Sliders items={productSelect.images} showDots={true} hideArrow={productSelect.images.length == 1} />
                        </div>

                        <div className="col-lg-6 pb-5">
                            {
                                user && user.role == 'admin' &&
                                <div className='text-right'>
                                    <button type="button" onClick={() => setEditProduct(productSelect)} className="btn btn-outline-dark ">Edit</button>
                                </div>
                            }
                            <h3 className="font-weight-semi-bold">{language == 'ua' ? productSelect.name : productSelect.en_name}</h3>
                            <div className="d-flex mb-3">
                                <div className="text-primary mr-2">
                                    <small className="fas fa-star"></small>
                                    <small className="fas fa-star"></small>
                                    <small className="fas fa-star"></small>
                                    <small className="fas fa-star-half-alt"></small>
                                    <small className="far fa-star"></small>
                                </div>
                                <small className="pt-1">({productSelect.code})</small>
                            </div>

                            <h3 className="font-weight-semi-bold mb-4">{language == 'ua' ? `${productSelect.price} грн.` : `${productSelect.en_price} Euro`} </h3>
                            <p className="mb-3">{language == 'ua' ? productSelect.shortDescription : productSelect.en_shortDescription}</p>

                            {
                                productSelect.material?.length > 0 &&
                                <div className='mt-1'>
                                    <div className='type-group'>
                                        {ad('Material.name')}:
                                        {
                                            productSelect.material?.map((el: any, i: number) =>
                                                <div key={i}>
                                                    {ad('Material.data')[el]}{i < productSelect.material.length - 1 && ','}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                            {
                                productSelect.holiday?.length > 0 &&
                                <div className='mt-1'>
                                    <div className='type-group'>
                                        {ad('Holiday.name')}:
                                        {
                                            productSelect.holiday?.map((el: any, i: number) =>
                                                <div key={i}>
                                                    {ad('Holiday.data')[el]}{i < productSelect.holiday.length - 1 && ','}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                            {
                                productSelect.interior?.length > 0 &&
                                <div className='mt-1'>
                                    <div className='type-group'>
                                        {ad('Interior.name')}:
                                        {
                                            productSelect.interior?.map((el: any, i: number) =>
                                                <div key={i}> {ad('Interior.data')[el]}{i < productSelect.interior.length - 1 && ','} </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                            {
                                productSelect.color?.length > 0 &&
                                <div className='mt-1'>
                                    <div className='type-group'>
                                        {ad('Color.name')}:
                                        <div className='color-item-group'>

                                            {
                                                productSelect.color?.map((el: any, i: number) =>
                                                    <div key={i} className='color-item  '>
                                                        {ad('Color.data').find(e => e.value == el)?.name}
                                                        {ad('Color.dataRose').find(e => e.value == el)?.name}
                                                        <div onClick={() => setColor(el)} className={`${el} ${el == color ? 'active' : ''} `}>
                                                            {(ad('Color.dataRose').find(e => e.value == el)?.value == color || ad('Color.data').find(e => e.value == el)?.value == color) && <i className='fas fa-check'></i>}

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="d-flex align-items-center mb-4 pt-4">

                                <div className="input-group quantity mr-3" style={{ width: "180px" }}>
                                    <div className="input-group-btn">
                                        <button onClick={() => changeCount(false)} className="btn btn-primary btn-minus" >
                                            <i className="fa fa-minus"></i>
                                        </button>
                                    </div>
                                    <div className="form-control bg-secondary text-center my-1"  > {count} </div>
                                    <div className="input-group-btn">
                                        <button onClick={() => changeCount(true)} className="btn btn-primary btn-plus">
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <button onClick={() => addToCart()} className="btn btn-primary px-3"><i className="fa fa-shopping-cart mr-1"></i> Add To Cart</button>
                            </div>

                        </div>
                    </div>
                    <div className="row px-xl-5 my-5">
                        <div className="col">
                            <div className="mt-5">
                                <h4 className="mb-3">{language == 'ua' ? 'Опис продукту' : 'Product Description'}</h4>
                                <p>{language == 'ua' ? productSelect.description : productSelect.en_description}</p>
                            </div>

                        </div>
                    </div>
                    {
                        products?.length > 0 &&
                        <>
                            <h3 className='text-center my-5 pt-5'>{language == 'ua' ? 'Вам також може сподобатися' : 'You May Also Like'}</h3>
                            <div className="row px-xl-5">
                                <div className="col slider-more">
                                    <Sliders slidesToShow={3} slidesToScroll={2} custom={true} autoplay={true} items={renderItems(products)} showDots={false} hideArrow={productSelect.images.length == 1} />
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
        </>
    )
}

export default withNamespaces()(ProductDetails); withRouter(ProductDetails);