import { useContext, useEffect, useState } from 'react';
import './orders.css';
import { AuthContext } from '../../../core/auth';
import { Product } from '../../../types/Product';
import { getOrders, updateOrder } from '../../../services/firebase-store.service';

export enum Status {
    'pending' = 'pending',
    'approve' = 'approve',
    'cancel' = 'cancel',
}

const filterOrder = [
    { isActive: true, name: 'В процесі', status: Status.pending },
    { isActive: true, name: 'Відправлено', status: Status.approve },
    { isActive: true, name: 'Відхилено', status: Status.cancel }
]

export const Orders = () => {
    const { user, setLoading } = useContext(AuthContext);
    const [orders, setOrders] = useState<any>([]);
    const [originOrders, setOriginOrders] = useState<any>([]);
    const [filters, setFilters] = useState<any>(filterOrder);


    useEffect(() => {
        if (user && user.role == 'admin')
            getData();
    }, [user]);

    const getData = async () => {
        setLoading(true);
        const _orders = await getOrders();
        if (_orders) {
            setOrders(_orders);
            setOriginOrders(_orders);
        }
        setLoading(false);
    }

    const getTotal = (prod: []) => {
        let total = 0;
        prod.map((el: any) => total += el.product.price.replace(/\,/g, '.') * el.count);
        return total;
    }

    const changeStatus = async (status: Status, item: any) => {
        setLoading(true);
        const _item = Object.assign({}, item);
        _item.status = Status[status];
        await updateOrder(_item);
        getData();
        filterData();
    }

    const onFilering = (type: any, value: boolean) => {
        const _filters = Object.assign([], filters);
        _filters[type].isActive = !value;
        setFilters(_filters);
        filterData();
    }

    const filterData = () => {
        const a: any = [];
        filters.map((el: any) => {
            if (el.isActive) {
                originOrders.map((or: any) => {
                    if ((or.status == el.status) || (!or.status && el.status == Status.pending)) {
                        a.push(or)
                    }
                })
            }
        })
        setOrders(a);
    }


    return (
        <div className="container-fluid pt-5 shopping-cart orders">
            <h2 className='text-center mb-4'>Замовлення</h2>

            <div className="d-flex filter-btns">
                <i className="fa fa-filter" aria-hidden="true" />
                {
                    filters.map((value: { isActive: boolean, name: string }, i: number) =>
                        <label onClick={() => onFilering(i, value.isActive)} className={`btn btn-outline-primary ${value.isActive ? 'active' : ''}`}>{value.name}</label>
                    )
                }
            </div>

            <div className="row px-xl-5">
                <div className="col-lg-12 table-responsive mb-5">
                    <table className="table table-bordered text-center mb-0">
                        <thead className="bg-secondary text-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Прізвище, Ім'я</th>
                                <th scope="col">Адреса</th>
                                <th scope="col">Продукти</th>
                                <th scope="col">Сума (грн)</th>
                                <th scope="col">Статус</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders?.map((pay: any, i: number) => (
                                    <tr key={i}>
                                        <td scope="row">{i + 1}</td>
                                        <td>{pay.address.firstName} {pay.address.lastName}
                                            <br /> {pay.address.email}
                                            <br /> {pay.address.phoneNumber}
                                        </td>
                                        <td> {pay.address.country}, {pay.address.state}, {pay.address.city}, {pay.address.address}, {pay.address.zip}</td>
                                        <td>
                                            {pay.products.map((prod: { product: Product, count: number, color: string }, ii: number) =>
                                                <td key={ii} className="align-middle img-name p-0">
                                                    <div className="align-middle img-name">
                                                        <img src={`${prod.product.images[0]}`} alt="" />
                                                        {prod.color && <div className='color-item'>
                                                            <div className={prod.color}>
                                                            </div>
                                                        </div>
                                                        }
                                                        <div> {prod.product.name} </div>
                                                    </div>
                                                    <div className='w-130'>
                                                        {prod.count} X < br />
                                                        {prod.product.price} грн
                                                    </div>
                                                </td>
                                            )}
                                        </td>
                                        <td className="align-middle">{getTotal(pay.products)}</td>
                                        <td className="align-middle">
                                            {
                                                pay.status == Status.pending || !pay.status
                                                    ? <div className="status-td">
                                                        <i className="fas fa-hourglass-end yellow" />
                                                        <div>В&nbsp;процесі</div>
                                                    </div>
                                                    : pay.status == Status.cancel
                                                        ? <div className="status-td">
                                                            <i className="fas fa-thumbs-down red" />
                                                            <div>Відхилено</div>
                                                        </div>
                                                        : <div className="status-td">
                                                            <i className="fas fa-thumbs-up" />
                                                            <div>Відправлено</div>
                                                        </div>
                                            }
                                        </td>
                                        <td className="edit-icon">
                                            <i className="fas fa-ellipsis-h" />
                                            <div className="edit-body">
                                                {
                                                    pay.status == Status.approve
                                                        ? <>
                                                            <div onClick={() => changeStatus(Status.cancel, pay)}><i className="fas fa-thumbs-down red" />  Відхилити</div>
                                                            <div onClick={() => changeStatus(Status.pending, pay)}><i className="fas fa-hourglass-end yellow" />  Скасувати</div>
                                                        </>
                                                        : pay.status == Status.cancel
                                                            ? <>
                                                                <div onClick={() => changeStatus(Status.pending, pay)}><i className="fas fa-hourglass-end yellow" />  Скасувати</div>
                                                            </>
                                                            : <>
                                                                <div onClick={() => changeStatus(Status.approve, pay)}> <i className="fas fa-thumbs-up" /> Відправлено</div>
                                                                <div onClick={() => changeStatus(Status.cancel, pay)}><i className="fas fa-thumbs-down red" />  Відхилити</div>
                                                            </>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}