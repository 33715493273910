import { useContext, useEffect, useState } from 'react';
import './login-form.css';
import { AuthContext, createUser, loginUser } from '../../core/auth';
import { User } from '../../types/auth';
import { countryOptions } from '../../services/local-data/countries';
import { NotificationSvc } from '../notification/Notification';


export const LoginForm = ({cancel}: any) => {
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<User>({} as User);
  const { changeUser, language } = useContext(AuthContext);
  const { onError } = NotificationSvc();

  const onLogin = () => {
    if (login && password) {
      loginUser(login, password).then((u: any) => {
        changeUser(u)
      })
    }
  }
  const onRegister = (e: any) => {
    e?.preventDefault();
    newUser.login = newUser.email || '';
    if (newUser.login && newUser.password) {
      createUser(newUser, newUser.login, newUser.password)
        .then((res: any) => { changeUser(res); })
        .catch(e => onError('', e))
    }
  }

  const changeRegisterValue = (value: any, property: string) => {
    const _newUser = Object.assign({}, newUser);
    _newUser[property] = value;
    setNewUser(_newUser);
  }


  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">

        <div className="fadeIn first pt-4">
          <h2>{isNewUser
            ? language == 'ua' ? 'РЕЄСТРАЦІЯ' : 'REGISTER'
            : language == 'ua' ? 'ВХІД' : 'LOGIN'}</h2>
        </div>
        <div className="close-btn" onClick={() => cancel()}>
                <i className="fas fa-window-close"></i>
            </div>
        {
          isNewUser
            ? <form onSubmit={(e) => onRegister(e)}>
              <div className="row">
                <div className="col-md-6 form-group">
                  <input required type="text" value={newUser.firstName || ''} onChange={(e) => changeRegisterValue(e.target.value, 'firstName')} className="fadeIn first" placeholder={language == 'ua' ? `Iм'я, по батькові` : 'First Name'} />
                  <input required type="email" value={newUser.email || ''} onChange={(e) => changeRegisterValue(e.target.value, 'email')} className="fadeIn first" placeholder={language == 'ua' ? 'Пошта' : 'E-mail'} />
                  <input required type="text" value={newUser.phoneNumber || ''} onChange={(e) => changeRegisterValue(e.target.value, 'phoneNumber')} className="fadeIn first" placeholder={language == 'ua' ? 'Телефон' : 'Mobile No'} />
                  <select required className="fadeIn first" value={newUser?.country || ''} onChange={(e) => changeRegisterValue(e.target.value, 'country')}>
                    <option value="">... {language == 'ua' ? 'Країна' : 'Сountry'}</option>
                    {countryOptions.map((el: any, i: number) =>
                      <option key={i} value={el.value}>{el.value}</option>
                    )}
                  </select>
                  <input required type="text" value={newUser.state || ''} onChange={(e) => changeRegisterValue(e.target.value, 'state')} className="fadeIn first" placeholder={language == 'ua' ? 'Область/район' : 'State'} />
                </div>

                <div className="col-md-6 form-group">
                  <input required type="text" value={newUser.lastName || ''} onChange={(e) => changeRegisterValue(e.target.value, 'lastName')} className="fadeIn first" placeholder={language == 'ua' ? 'Прізвище' : 'Last Name'} />
                  <input required type="password" value={newUser.password} onChange={(e) => changeRegisterValue(e.target.value, 'password')} className="fadeIn first" placeholder={language == 'ua' ? 'Пароль' : 'Password'} />
                  <input required type="text" value={newUser.address || ''} onChange={(e) => changeRegisterValue(e.target.value, 'address')} className="fadeIn first" placeholder={language == 'ua' ? 'Адреса' : 'Address'} />
                  <input required type="text" value={newUser.city || ''} onChange={(e) => changeRegisterValue(e.target.value, 'city')} className="fadeIn first" placeholder={language == 'ua' ? 'Місто' : 'City'} />
                  <input required type="text" value={newUser.zip || ''} onChange={(e) => changeRegisterValue(e.target.value, 'zip')} className="fadeIn first" placeholder={language == 'ua' ? 'Поштовий індекс' : 'ZIP Code'} />
                </div>
              </div>
              <div id="formFooter">
                <input type="submit" className="fadeIn fourth" value={language == 'ua' ? 'Cтворити обліковий запис' : 'Create account'} />
              </div>
            </form>
            : <>
              <input type="email" value={login} onChange={(e) => setLogin(e.target.value)} className="fadeIn second" name="login" placeholder={language == 'ua' ? 'Пошта' : 'E-mail'} />
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="fadeIn third" name="login" placeholder={language == 'ua' ? 'Пароль' : 'Password'} />
              <input type="button" onClick={() => onLogin()} className="fadeIn fourth" value={language == 'ua' ? 'Вхід' : 'Log In'} />
              <div id="formFooter">
                <a className="underlineHover" onClick={(e) => setIsNewUser(true)} href="#">{language == 'ua' ? 'Реєстрація' : 'Register'}</a>
              </div>
            </>
        }

      </div>
    </div>
  )
}