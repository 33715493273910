import { useCallback, useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./navbar.css";
import logo_text from "../../asset/logo/logo-text2.jpg";
import i18n from "../../core/i18n";
import ua_photo from '../../asset/ukr.png';
import uk_photo from '../../asset/uk.png';
import { LoginForm } from "../login/login-form";
import { AuthContext, logout } from "../../core/auth";
import { getOrders } from "../../services/firebase-store.service";

const Navbar = () => {
    const [activeLan, setActiveLan] = useState<any>('ua');
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);
    const { user, cart, language } = useContext(AuthContext);


    useEffect(() => {
        if (user) {
            setShowLogin(false);
            if (user && user.role == 'admin') {
                getCount();
            }
        }
    }, [user]);

    useEffect(() => {
        const _localCart: any = localStorage.getItem("cart");
        const _cart = JSON.parse(_localCart);
        if (_cart) {
            setCount(_cart.length);
        }

    }, [cart]);

    const getCount = async () => {
        const a: any = await getOrders();
        if (a && a.length > 0) {
            setCount(a.length);
        }
    }

    const onLogOut = useCallback(() => void logout().then(() => {
        window.location.replace('/');
    }), []);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setActiveLan(lng);
    }

    return (
        <div className="container">
            <div className="row bg-white px-xl-5">
                <div className="col-lg-9">
                    <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">

                        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div className="navbar-nav mr-auto py-0">
                                <NavLink to={'/'} className="text-decoration-none">
                                    <div className="logo-main">
                                        <img src={logo_text} alt="" />
                                    </div>
                                </NavLink>
                                <NavLink to="/" className="nav-item nav-link">{activeLan == 'ua' ? 'Домашня' : 'Home'}</NavLink>
                                <NavLink to={'/production'} className="nav-item nav-link">{activeLan == 'ua' ? 'Виробництво' : 'Production'}</NavLink>
                                <NavLink to={'/contact'} className="nav-item nav-link">{activeLan == 'ua' ? 'Контакти' : 'Contacts'}</NavLink>
                                {
                                    user && user.role == 'admin' &&
                                    <NavLink to={'/admin'} className="nav-item nav-link">{activeLan == 'ua' ? 'Додати продукт' : 'Add product'} </NavLink>
                                }
                            </div>

                            <div className="language">
                                <div className={`${activeLan == 'ua' ? 'active' : ''}`}>
                                    <img src={ua_photo} onClick={() => changeLanguage('ua')}></img>
                                </div>
                                <div className={`${activeLan == 'en' ? 'active' : ''}`}>
                                    <img src={uk_photo} onClick={() => changeLanguage('en')}></img>
                                </div>


                                <div>
                                    {
                                        user && user.role == 'admin'
                                            ? <NavLink to={'/admin/orders'}>
                                                <button type="button" className="btn btn-primary position-relative mr-3 large-icon">
                                                    <i className="fas fa-cart-arrow-down"></i>
                                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                        {count > 0 && count}
                                                    </span>
                                                </button>
                                            </NavLink>
                                            : <NavLink to={'/cart'}>
                                                <button type="button" className="btn btn-primary position-relative mr-3">
                                                    <i className="fas fa-shopping-cart"></i>
                                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                        {count > 0 && count}
                                                    </span>
                                                </button>
                                            </NavLink>
                                    }
                                </div>
                            </div>
                                <div className="social-i">
                            {
                                    user
                                    ? <div className="user-name">
                                        {user.role == 'admin'  ? 'Admin' : user.login}
                                        <div className="m-items">
                                            <div className="m-item" onClick={() => onLogOut()}> {language == 'ua' ? 'Вихід' : 'Logout'}  <i className="fas fa-sign-out-alt" /> </div>
                                            <NavLink to={'/profile'} className="m-item" >
                                                {language == 'ua' ? 'Профіль' : 'Profile'}  <i className="fas fa-user" />
                                            </NavLink>
                                            {/* <div className="m-item" onClick={() => onLogOut()}>{language == 'ua' ? 'Профіль' : 'Profile'}  <i className="fas fa-user" /></div> */}
                                        </div>
                                    </div>
                                    : <div className="login-btn" onClick={() => setShowLogin(!showLogin)}>
                                       {language == 'ua' ? 'Вхід' : 'login'}  
                                    </div>
                            }
                                </div>
                        <div className="social-i">
                            <a className="text-dark px-2" target="_blank" href="https://www.instagram.com/white_rose_ua/?igsh=MTNjM3gyNHhiaWl1Zw%3D%3D&utm_source=qr">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </div>
                        </div>

                    </nav>

                </div>
            </div>
            {showLogin && <LoginForm cancel={() => setShowLogin(!showLogin)} />
            }
        </div>
    )
};

export default Navbar;