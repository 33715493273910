import { useEffect, useState, useCallback, FC } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthContext } from "./core/auth";
// import ErrorBoundary from "./components/ErrorBoundary";
import { firebaseAuth } from "./core/firebase";
import { User } from "./types/auth";
import  AddProduct from './pages/Admin/AddProduct/addProduct';
import { getData } from './services/firebase-store.service';
import { NotificationSvc } from "./components/notification/Notification";
import { PrivateRoute } from "./components/ProtectedRoute";
import Layout from "./pages/Layout";
import './index.css';
import './components/navigation/navbar.css'
import Home from "./pages/Home/Home";
import ProductDetails from "./components/product-details/product-details";
import { ShoppingCart } from "./components/shopping-cart/shopping-cart";
import { Product } from "./types/Product";
import { withNamespaces } from 'react-i18next';
import Contact from "./pages/Coontacts/Contact";
import i18n from "./core/i18n";
import { Checkout } from "./components/checkout/checkout";
import { Orders } from "./pages/Admin/Orders/orders";
import { Profile } from "./pages/Profile/profile";
import  Production  from "./pages/Production/production";


const App: FC = ({ t }: any) => {
  const [user, setUser] = useState<User | null | undefined>(null);
  const [productSelect, setProductSelect] = useState<Product>({} as Product);
  const [products, _setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [cart, _setCart] = useState<any>([]);
  const { notifications } = NotificationSvc();
  const [language, setLanguage] = useState<string | undefined>('ua');

  const changeUser = useCallback((user: any) => {
    user && user.roles && user.roles.length !== 0
      ? setUser(new User(user))
      : setUser(user);
    setLoading(false);
  }, []);

  useEffect(() => {
    setLanguage(i18n?.resolvedLanguage);
    languageChanged();
  }, []);

  const languageChanged = () => {
    i18n.on('languageChanged', function (lng) {
      setLanguage(lng);
    })
  }

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async usr => {
      if (usr && !user) {
        setLoading(true);
        const _user = await getData(usr.uid, 'users');
        changeUser(_user);
      } else if (!usr) {
        changeUser(null);
      }
    });
    return () => unsubscribe();
  }, [user]);

  const setProducts = (_products: Product[]) => {
    _setProducts(_products);
  }

  const setCart = (items: any) => {
    localStorage.setItem("cart", JSON.stringify(items));
    _setCart(items);
  }



  return (
    // <ErrorBoundary>
    <AuthContext.Provider value={{
      user,
      changeUser,
      setLoading,
      productSelect,
      setProductSelect,
      products,
      setProducts,
      loading,
      language,
      setLanguage,
      cart,
      setCart
    }}>

      {
        loading &&
        <div className="load-indicator" aria-disabled="true">
          <figure>
            <div className="dot white"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </figure>
        </div>
      }

      {notifications()}


      <Router>
        <Switch>
          <Layout>
            <Route exact path="/" component={Home} />
            <Route exact path="/details/:id" component={ProductDetails} />
            <Route exact path="/cart" component={ShoppingCart} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/production" component={Production} />

            <Route exact path="/admin" component={AddProduct} />
            <Route exact path="/admin/orders" component={Orders} />
            {/* <Route exact path="/profile" component={Profile} /> */}
            {
                user && <PrivateRoute user={user} exact path="/profile" comp={Profile} />
                  // : <Route exact path="/" component={Home} />
              }
          </Layout>
        </Switch>
      </Router>
    </AuthContext.Provider>
    // {/* </ErrorBoundary> */}
  );
};
export default withNamespaces()(App);